import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import ContainerPage from "../../_Common/ContainerPage";
import { CheckSvg, DisableSvg, EditSvg, FlechDwnSvg, OutSvg, RefreshSvg } from "../../_Common/IconSvg";
import InlinedElement from "../../_Common/InlinedElement";
import IconButton from "../../_Common/IconButton";
import { EmptyScreen } from "../../_Common/EmptyScreen";
import CustomTable, { TableColumnProps } from "../../_Common/CustomTable/CustomTable";
import { ProductType, RequestStatus, StockReleaseItem, StockReleaseItemProduct, StockReleaseUpdate } from "../../../../types";
import GroupeBtn from "../../_Common/GroupeBtn";
import { NumberParam, StringParam, encodeQueryParams, useQueryParams } from "use-query-params";
import PaginationBar from "../../_Common/PaginationBar";
import { FormatDate, Translate } from "../../../../utils/lang/translate";
import { alertActions, productActions } from "../../../../actions";
import { connect } from "react-redux";
import { FeatureLoked } from "../../_Common/FeatureLoked";
import { StockReleaseRequestStatusStyle } from "../../../../constants";
import { stringify } from "query-string";
import DropedList from "../../_Common/DropedList";
import TabShow from "../../_Common/TabShow";
import BtnLg from "../../_Common/BtnLg";
import StockReleaseFormModal from "./StockReleaseFormModal";
import Cookies from "js-cookie";
import NoStockReleaseImage from "../../../../utils/img/no-stock-releases.png";

const lang = Cookies.get("lang");

interface StockReleaseProps {
  SendAlert: (code: string, text: string, action: string) => void,
  dataProfile: any,
  GettingStockReleases: RequestStatus,
  dataStockReleases: { 
    count: number,
    results: StockReleaseItem [],
    next: string | null,
    previous: string | null
  },
  GetStockReleases: (filter?: string) => void,
  UpdateStockReleaseRequest: (request_id: string, data: StockReleaseUpdate) => void,
  UpdatingStockRelease: RequestStatus,
  GetAllProduct: () => void,
  ClearStockReleaseItem: () => void,
  GetAllProductsV2: () => void,
  GettingAllProducts: RequestStatus,
  productsData: {
    list: ProductType[],
  },
  updateStockReleaseError: {
    non_field_errors: string[]
  }
}

const StockRelease = ({
  SendAlert,
  dataProfile,
  GettingStockReleases,
  dataStockReleases,
  GetStockReleases,
  UpdateStockReleaseRequest,
  UpdatingStockRelease,
  ClearStockReleaseItem,
  GetAllProductsV2,
  GettingAllProducts,
  productsData,
  updateStockReleaseError
}: StockReleaseProps) => { 

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,	
  });

  const [displayId, setDisplayId] = useState<string>("");
  const [refresh, setRefresh] = useState(false);
  const [abortId, setAbortId] = useState<string | null>(null);
  const [editId, setEditId] = useState<string | null>(null);
  
  type StockReleaseColumnType = StockReleaseItem & { action: string };
  const StockReleaseCols: TableColumnProps<StockReleaseColumnType, keyof StockReleaseColumnType>[] = [
    {
      key: "display_id",
      title: Translate("product", "ID"),
    },
    {
      key: "products",
      title: Translate("sidebar", "products"),
      renderItem: (item) => (
        item.products.length > 1 ?
          <div className="CrsPoi">
            <DropedList
              title={
                <div className="InFlx AlgnItm">
                  <span>{item.products.length}</span>
                  <span className="StPaddingVal">
                    {Translate("titles", "product")}
                  </span>
                  <span className="DsPlCnt">{FlechDwnSvg}</span>
                </div>
              }
              dataDrop={item.products.map((product, index) => (
                <div key={index}>{`(${product.quantity}) ${product.product_name}`}</div>
              ))} 
            />  
          </div>
        : 
          <div>
            {`(${item.products[0].quantity}) ${item.products[0].product_name}`}
          </div>
      )
    },
    {
      key: "status",
      title: Translate("orders", "status"),
      renderItem: (item) => (
        <div 
          className="StBrdRd HdOvrfl TxtCn" 
          style={{
            padding: "6px 15px", 
            backgroundColor: StockReleaseRequestStatusStyle[item.status].bgColor 
          }}
        >
          {StockReleaseRequestStatusStyle[item.status].text}
        </div>
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd HdOvrfl animate-pulse" 
          style={{ 
            width: "150px", 
            height: "26px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    },
    {
      key: "created_at",
      title: Translate("orders", "creationdate"),
      renderItem: (item) => <>{ FormatDate(item.created_at) }</>
    },
    {
      key: "action",
      title: Translate("product", "action"),
      renderItem: (item) => (
        <div className="InFlx JstfCnt">
          <GroupeBtn 
            data_btn={
              item.status === 2 ?
              [
                {
                  svgbtn: EditSvg,
                  type: "BDgInpc",
                  action: () => {
                    if(!GettingAllProducts || GettingAllProducts === "3") GetAllProductsV2();
                    setEditId(item.id);
                  },
                },
                {
                  svgbtn: DisableSvg,
                  type: "BDgInpc",
                  action: () => setAbortId(item.id),
                }
              ] : []
            }
          />
        </div>
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd MrAot HdOvrfl animate-pulse" 
          style={{ 
            width: "60px", 
            height: "32px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    },
  ]; 

  const onChangePage = (pageNumber: number) => {
    setQuery(prev => ({ ...prev, page: pageNumber }));
  };

  const handleDisplayIdSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(query.search) {
      setQuery(prev => ({ ...prev, search: undefined, page: undefined }));
      setDisplayId("");
    }
    else setQuery(prev => ({ ...prev, search: displayId, page: undefined }));
  }

  const handleDisplayIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayId(e.target.value);
  };

  const refreshPage = () => {
    setRefresh(prev => !prev)
  };

  const closeEditModal = () => setEditId(null);
  
  const handleStockReleaseUpdate = (
    request_id: string, 
    data: Omit<StockReleaseItemProduct, "product_name">[]
  ) => {
    UpdateStockReleaseRequest(request_id, { products: data });
  }

  useEffect(() => {
    
    const { page, search } = query;

    const encodedQuery = encodeQueryParams({
      page: NumberParam,
      search: StringParam,	
    }, { page, search });
  
    GetStockReleases(`?${stringify(encodedQuery)}`); 
  }, [query.page, query.search, refresh]);

  useEffect(() => {
    
    if(GettingStockReleases === "3") SendAlert("50", "Failed to fetch data!", "");
    
  }, [GettingStockReleases]);

  useEffect(() => {
    
    if(UpdatingStockRelease === "2") {
      setEditId(null);
      SendAlert("41", "Successfuly updated!", "");
    }
    if(UpdatingStockRelease === "3") {
      SendAlert("50", "Failed to update!", "");
    }

  }, [UpdatingStockRelease]);

  useEffect(() => {
    return () => ClearStockReleaseItem();
  }, []);

  return (
    <ContainerPage
      page_title={Translate("product", "stockrelease")}
    >
      {
        (dataProfile.is_store || dataProfile.view_stock)
        ?
          <div>
            <div
              className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
              style={{ height: "48px", padding: "7px 0", top: 0 }}
            >
              <form onSubmit={handleDisplayIdSubmit}>
                <div className="FlHg StBrdRdS ClSidTh">
                  <InlinedElement
                    style={{ padding: "0" }}
                    secnd={
                      <div>
                        <input
                          type="text"
                          autoComplete="off"
                          onChange={handleDisplayIdChange}
                          name="filterbyid"
                          style={{ width: "300px", maxWidth: "30vw" }}
                          className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                          value={query.search ?? displayId}
                          placeholder={"Search by id or external id"}
                          disabled={GettingStockReleases === "1"}
                        />
                      </div>
                    }
                    leftElm={
                      <button
                        className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                        style={{ padding: "10px" }}
                      >
                        { query.search ? OutSvg : CheckSvg }
                      </button>
                    }
                  />
                </div>
              </form>
              <div className="FlWd">
                <div
                  className="InFlx FlHg"
                  style={
                    lang === "ar" ?
                    { gap: "12px", marginRight: "20px" }
                    : { gap: "12px", marginLeft: "20px" }
                  }
                >
                  <IconButton
                    clickHandler={refreshPage}
                    icon={RefreshSvg}
                  />
                </div>
              </div>
            </div>
            {
              (GettingStockReleases === "2" && dataStockReleases.results.length === 0)
              ?
                <EmptyScreen
                  titleGlb={Translate("product", "nostockreleases")}
                  TextShow={Translate("product", "nostockreleasesyet")}
                  PictShow={NoStockReleaseImage}
                />
              :
                <>
                  <CustomTable 
                    columns={StockReleaseCols} 
                    data={
                      GettingStockReleases === "2"
                      ? dataStockReleases.results.map((item, _) => ({ ...item, action: "" }))
                      : []
                    } 
                    loading={GettingStockReleases === "1"}
                    border={false}
                    sticky={true}
                  />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span className="d-large">
                        {
                          Translate("orders", "showing") +
                          " " +
                          ((query.page ?? 1) * 20 - 19) +
                          " - " +
                          (
                            (query.page ?? 1) * 20 < (dataStockReleases?.count ?? 0)
                            ? (query.page ?? 1) * 20
                            : dataStockReleases?.count ?? 0
                          ) +
                          " " +
                          Translate("orders", "of") +
                          " " +
                          dataStockReleases?.count ?? 0
                        }
                      </span>
                      <PaginationBar
                        className="StAutMr"
                        NbPage={Math.trunc((dataStockReleases?.count ?? 0) / 20) + 1}
                        currentPage={query.page ?? 1}
                        ChangePage={onChangePage}
                        blockNext={dataStockReleases?.next}
                        blockPrev={dataStockReleases?.previous}
                      />
                    </div>
                  </div>
                </>
            }

            {
              abortId !== null
              &&
              <TabShow
                tab_title={Translate("profile", "confirmaction")}
                wdth="550px"
                darkBg
                noReturn
              >
                <div className="MrAot" style={{ width: "94%" }}>
                  <p className="StdirLn FlWd DltTpMar">
                    {Translate("TeamsPage", "confirmtoggle")}
                  </p>
                  <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                    <div
                      className="StwdMaxC CrsPoi StWdDst"
                      style={{ height: "30px" }}
                      onClick={() => {
                        setAbortId(null);
                      }}
                    >
                      <BtnLg
                        text={Translate("profile", "cancel")}
                        classSet={""}
                      />
                    </div>
                    <div
                      className="StwdMaxC CrsPoi StWdDst"
                      style={{ height: "30px" }}
                      onClick={() => {
                        UpdateStockReleaseRequest(abortId, { status: 1 });
                        setAbortId(null);
                      }}
                    >
                      <BtnLg
                        text={Translate("shop", "confirm")}
                        classSet={"TxDng"}
                      />
                    </div>
                  </div>
                </div>
              </TabShow>
            }
            
            {
              (editId !== null && GettingStockReleases === "2")
              &&
              <StockReleaseFormModal
                onClose={closeEditModal}
                onFormSubmit={(data: Omit<StockReleaseItemProduct, "product_name">[]) => handleStockReleaseUpdate(editId, data)}
                releaseProducts={
                  dataStockReleases.results
                  .find((item, _) => item.id === editId)?.products
                  .map((product, _) => ({ product: product.product, quantity: product.quantity }))
                }
                loading={UpdatingStockRelease === "1" || GettingAllProducts === "1"}
                productsList={GettingAllProducts === "2" ? productsData.list : []}
                error={
                  UpdatingStockRelease === "3" 
                  && updateStockReleaseError.non_field_errors
                  ? updateStockReleaseError.non_field_errors 
                  : null
                }
              />
            }
          </div>
        :
          <FeatureLoked/>
      }
    </ContainerPage>
  );
};


function mapState(state: any) {
  const { dataProfile } = state.user;
  const { 
    GettingStockReleases, 
    dataStockReleases, 
    UpdatingStockRelease, 
    GettingAllProducts,
    productsData,
    updateStockReleaseError
  } = state.product;
  
  return { 
    dataProfile, 
    GettingStockReleases, 
    dataStockReleases, 
    UpdatingStockRelease, 
    GettingAllProducts,
    productsData,
    updateStockReleaseError
  };
};

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  GetStockReleases: productActions.GetStockReleases,
  UpdateStockReleaseRequest: productActions.UpdateStockReleaseRequest,
  GetAllProduct: productActions.GetAllProduct,
  GetAllProductsV2: productActions.GetAllProductsV2,
  ClearStockReleaseItem: productActions.ClearStockReleaseItem
}

const connectedStockReleasePage = connect(mapState, actionCreators)(StockRelease);
 
export { connectedStockReleasePage as StockReleasePage };