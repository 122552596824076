import { refundConstants } from "../constants/refund.constants";
import { RequestStatus } from "../types";

interface RefundState {
  GettingRefunds?: RequestStatus,
  refundsData?: any,
  refundDetail?: any
};

interface RefundAction {
  type: keyof typeof refundConstants,
  payload: any
};

export function refunds(state: RefundState, action: RefundAction): RefundState {
  switch (action.type) {
    case refundConstants.GET_REFUNDS_REQUEST:
      return {
        ...state,
        GettingRefunds: "1",
      };
    case refundConstants.GET_REFUNDS_SUCCESS:
      return {
        ...state,
        GettingRefunds: "2",
        refundsData: action.payload
      };
    case refundConstants.GET_REFUNDS_FAILURE:
      return {
        ...state,
        GettingRefunds: "3",
        refundsData: action.payload
      }
    case refundConstants.GET_REFUND_DETAILS_REQUEST:
        return{
          ...state,
          GettingRefunds: "1",
        }
    case refundConstants.GET_REFUND_DETAILS_SUCCESS:
       return{
          ...state,
          GettingRefunds: "2",
          refundDetail: action.payload
       }
    case refundConstants.GET_REFUND_DETAILS_FAILURE:
        return{
          ...state,
          GettingRefunds: "3",
          refundDetail: action.payload
        }
    default: 
      return {
        ...state
      }
  }
}