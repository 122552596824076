import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RequestStatus } from '../../../types';
import ContainerPage from '../_Common/ContainerPage';
import { alertActions, RefundActions } from '../../../actions';
import { decodeDelimitedNumericArray, encodeDelimitedNumericArray, encodeQueryParams, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { RouterChildContext, useHistory } from 'react-router-dom';
import { RefundListItem } from './types';
import { stringify } from 'query-string';
import { FormatDate, Translate } from '../../../utils/lang/translate';
import { FeatureLoked } from '../_Common/FeatureLoked';
import IconButton from '../_Common/IconButton';
import { CheckSvg, CloseSvg, FilterSvg2, FlechDwnSvg, OutSvg, RefreshSvg } from '../_Common/IconSvg';
import InlinedElement from '../_Common/InlinedElement';
import { refund_status_list } from '../../../constants/refund.constants';
import CheckBoxForm from '../../_Common/CheckBoxForm';
import Cookies from 'js-cookie';
import { EmptyScreen } from '../_Common/EmptyScreen';
import CustomTable, { TableColumnProps } from '../_Common/CustomTable/CustomTable';
import PaginationBar from '../_Common/PaginationBar';
import DropedList from '../_Common/DropedList';
import { PREMIUM_FILTER_STATUS_LIST, STARTER_FILTER_STATUS_LIST } from '../../constants';

const lang = Cookies.get("lang");

interface RefundsPageProps {
  GetRefunds: (filter: string) => void,
  GettingRefunds: RequestStatus,
  refundsData: {
    count: number,
    next: string | null,
    previous: string | null,
    results: RefundListItem[] 
  },
  SendAlert: (code: string, text: string, action: string) => void,
  dataProfile: any,
  dtStoreinfo: any,
};

const CommaArrayParam = {
  encode: (array: (number | null)[] | null | undefined) =>
    encodeDelimitedNumericArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedNumericArray(arrayStr, ',')
};

const RefundsPage = ({
  GettingRefunds,
  refundsData,
  GetRefunds,
  SendAlert,
  dataProfile,
  dtStoreinfo
}: RefundsPageProps) => {

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    display_id: StringParam,
    status: CommaArrayParam,	
  });

  const history = useHistory<RouterChildContext['router']['history']>();

  const FilterRef = useRef<HTMLDivElement>(null);
  const statusFilterRef= useRef<HTMLDivElement>(null);

  const [displayId, setDisplayId] = useState<string>("");
  const [refresh, setRefresh] = useState(false);

  const table_cols: TableColumnProps<RefundListItem, keyof RefundListItem>[] = [
    {
      key: "display_id",
      title: Translate("product", "ID"),
      renderItem: (item) => (
        <div 
          className="CrsPoi"
          style={{ paddingRight: "20px" }} 
          onClick={() => history.push(`/order/detail/${item.display_id}?source=refund`)}
        >
          {item.display_id}
        </div>
      ),
    },
    {
      key: "products",
      title: Translate("exchanges", "returnedproducts"),
      renderItem: (item) => (
        item.products.length > 1 ?
          <div className="CrsPoi">
            <DropedList
              title={
                <div className="InFlx AlgnItm">
                  <span>{item.products.length}</span>
                  <span className="StPaddingVal">
                    {Translate("titles", "product")}
                  </span>
                  <span className="DsPlCnt">{FlechDwnSvg}</span>
                </div>
              }
              dataDrop={item.products.map((product, index) => (
                <div key={index}>{`(${product.quantity}) ${product.logistical_description}`}</div>
              ))} 
            />  
          </div>
        :
          item.products.length > 0
          ?
            <div>
              {`(${item.products[0].quantity}) ${item.products[0].logistical_description}`}
            </div>
          : null
      )
    },
    {
      key: "status",
      title: Translate("orders", "status"),
      renderItem: (item) => (
        <div 
          style={{ padding: "6px 15px" }}
          className={`CrsPoi StWht StBrdRd HdOvrfl TxtCn status_${(item.status === 50 && item?.abort_reason_name === "Refund Completed") ? 41 : item.status}`}
          onClick={() => history.push(`/order/detail/${item.display_id}?source=refund`)}
        >
          {(item?.status === 50 && item?.abort_reason_name === "Refund Completed" ) ? Translate("refundComplted", item.status) :Translate("statusorder", item.status)}
        </div>
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd HdOvrfl animate-pulse" 
          style={{ 
            width: "150px", 
            height: "26px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    },
    {
      key: "wilaya",
      title: Translate("orders", "district") + " / " + Translate("product", "wilaya"),
      renderItem: (item) => (
        <div
          className="CrsPoi"
          onClick={() => history.push(`/order/detail/${item.display_id}?source=order`)}
        >
          {`${item.commune_name} / ${item.wilaya}`}
        </div>
      )
    },
    {
      key: "created_at",
      title: Translate("orders", "creationdate"),
      renderItem: (item) => (
        <div 
          className="CrsPoi"
          style={{ textAlign: "center" }}
          onClick={() => history.push(`/order/detail/${item.display_id}?source=order`)}
        >
          { FormatDate(item.created_at) }
        </div>
      )
    }
  ];

  const handleDisplayIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayId(e.target.value);
  };

  const handleDisplayIdSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(query.display_id) {
      setQuery(prev => ({ ...prev, display_id: undefined, page: undefined }));
      setDisplayId("");
    }
    else setQuery(prev => ({ ...prev, display_id: displayId, page: undefined }));
  };

  const clearFilters = () => {  
    setQuery(prev => ({ ...prev, status: undefined, page: undefined }));
  };

  const onChangeStatus = (status: number) => {
    setQuery(prev => {
      if(prev.status){
        if(prev.status.includes(status)) {
          if(prev.status.length === 1) return { ...prev, status: undefined, page: undefined };
          else return { ...prev, status: prev.status.filter(item => item !== status), page: undefined };
        }
        else return { ...prev, status: [...prev.status, status], page: undefined }
      }else return { ...prev, status: [status], page: undefined };
    });
  };

  const onChangePage = (pageNumber: number) => {
    setQuery(prev => ({ ...prev, page: pageNumber }));
  }

  const refreshPage = () => {
    setRefresh(prev => !prev)
  };

  const handleClickOutsideFilter = function(event: MouseEvent) {
    if (
      FilterRef &&
      FilterRef.current &&
      !FilterRef.current.contains(event.target as Node)
    ) {
      let clsList = document.getElementById("Filter_container");
      if (clsList && !clsList.classList.value.includes("hidElem2")){
        clsList?.classList.add("hidElem2");
      }
    }
  };

  const ShowFilter = function() {
    let clsList = document.getElementById("Filter_container");
    if (clsList && clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", handleClickOutsideFilter);
    } else {
      clsList?.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    }
  };

  const ControlFilter = (filterId: string, ref: React.RefObject<HTMLDivElement>) => {
    let clsList = document.getElementById(filterId);
    console.log(ref)
    if(clsList){
      if (clsList.classList.value.indexOf("hidElem3") !== -1) {
        clsList.classList.remove("hidElem3");
        clsList.style.maxHeight = "400px";
        document.addEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      } else {
        clsList.style.maxHeight = "0px";
        clsList.classList.add("hidElem3");
        document.removeEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    if(GettingRefunds === '3') SendAlert("50", "Failed to fetch refunds data", "");
  }, [GettingRefunds])

  useEffect(() => {
    
    const { page, display_id, status } = query;

    const encodedQuery = encodeQueryParams({
      page: NumberParam,
      display_id: StringParam,
      status: CommaArrayParam,
    }, { 
      page, 
      display_id, 
      status, 
    });
  
    GetRefunds(`?${stringify(encodedQuery)}`); 
  }, [query.page, query.display_id, query.status, refresh]);

  return (
    <ContainerPage page_title={Translate("refunds", "refunds")}>
      {
        (dataProfile.is_store || dataProfile.view_order)
        ?
          <div>
            <div
              className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
              style={{ height: "48px", padding: "7px 0", top: 0 }}
            >
              <div role="filter-data" className="FlHg InFlx StwdMaxC">
                <div
                  className="FlHg StMrtg2 InFlx AlgnItm"
                  ref={FilterRef}
                >
                  <IconButton 
                    clickHandler={ShowFilter} 
                    icon={FilterSvg2} 
                    className="BdgBlcl"
                  />
                  <div
                    id="Filter_container"
                    className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                    style={{ width: "350px" }}
                  >
                    <div id="Sub_Container_filter">
                      <div style={{ marginTop: "8px" }}>
                        <div className="RlPs">
                          <InlinedElement
                            secnd={
                              <strong>{Translate("orders", "filter")}</strong>
                            }
                            leftElm={
                              <div
                                onClick={ShowFilter}
                                className="InFlx CrsPoi IcnSizSm"
                              >
                                {CloseSvg}
                              </div>
                            }
                            style={{ width: "100%", padding: "0px" }}
                          />
                        </div>
                        {
                          Object.keys(query).filter((key) => {
                            if(key === "page" || key === "display_id" || key === "type") return false;
                            if(key === "status" && !query.status ) return false;
                            else return true; 
                          }).length > 0
                          &&
                          <span
                            className="CrsPoi StBle DsBlc StdirLn"
                            onClick={clearFilters}
                          >
                            {Translate("orders", "resetfilter")}
                          </span>
                        }
                      </div>
                      <div
                        ref={statusFilterRef}
                        className="BdgClN StBrdRdS StPdsml SmMarS1"
                      >
                        <div
                          className="StHeivl RlPs CrsPoi"
                          onClick={() => ControlFilter("Status_Container", statusFilterRef)}
                        >
                          <InlinedElement
                            secnd={
                              <strong>{Translate("refunds", "refundstatus")}</strong>
                            }
                            leftElm={
                              <div className="InFlx">{FlechDwnSvg}</div>
                            }
                            style={{ width: "100%", padding: "0px" }}
                          />
                        </div>
                        <div
                          id="Status_Container"
                          className="hidElem3 stTranEs"
                          style={{ overflowY: "auto" }}
                        >
                          <div>
                          {
                            (dtStoreinfo.stock_managed ? PREMIUM_FILTER_STATUS_LIST : STARTER_FILTER_STATUS_LIST)
                            .map((value, index) => (
                              <CheckBoxForm
                                key={index}
                                id={"S" + value}
                                name={"S" + value}
                                text={Translate("statusorder", value)}
                                workfun={() => {
                                  onChangeStatus(Number(value));
                                }}
                                check={query.status?.includes(Number(value))}
                              />
                            ))
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleDisplayIdSubmit}>
                  <div className="FlHg StBrdRdS ClSidTh">
                    <InlinedElement
                      style={{ padding: "0" }}
                      secnd={
                        <div>
                          <input
                            type="text"
                            autoComplete="off"
                            onChange={handleDisplayIdChange}
                            name="filterbyid"
                            style={{ width: "300px", maxWidth: "30vw" }}
                            className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                            value={query.display_id ?? displayId}
                            placeholder={"Search by id or external id"}
                            disabled={false}
                          />
                        </div>
                      }
                      leftElm={
                        <button
                          className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                          style={{ padding: "10px" }}
                        >
                          { query.display_id ? OutSvg : CheckSvg }
                        </button>
                      }
                    />
                  </div>
                </form>
              </div>
              <div className="FlWd">
                <div
                  className="InFlx FlHg"
                  style={
                    lang === "ar" ?
                    { gap: "12px", marginRight: "20px" }
                    : { gap: "12px", marginLeft: "20px" }
                  }
                >
                  <IconButton
                    clickHandler={refreshPage}
                    icon={RefreshSvg}
                  />
                </div>
              </div>
            </div>
            <div 
              className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm"
            >
              {
                query.status && query.status.length > 0
                &&
                <>
                  <div className="stBdp StSmlS StwdMaxC"> {Translate("refunds", "refundstatus")} </div> 
                  {
                    query.status?.map((status, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          onChangeStatus(status ?? -1);
                        }}
                        className="stBdp CrsPoi SthgMaxC"
                      >
                        <InlinedElement
                          style={{
                            border: "2px solid #3498DB",
                            padding: "3px 6px",
                            backgroundColor: "#3498DB35",
                            borderRadius: "5px",
                          }}
                          secnd={
                            <div className="IcnSiz" style={{ zoom: ".48" }}>
                              {CloseSvg}
                            </div>
                          }
                          first={
                            <div className="StSmlS StwdMaxC">
                              {Translate("statusorder", status)}
                            </div>
                          }
                        />
                      </div>
                    ))
                  }
                </>
              }
            </div>
            {
              (GettingRefunds === '3' && refundsData.results.length === 0)
              ?
              <EmptyScreen
                  titleGlb={Translate("refunds", "norefunds")}
                  TextShow={Translate("refunds", "norefundsyet")}
                  PictShow={"/assets/img/exchange.png"}
                  actionBtns={
                    Object.keys(query).filter(key => {
                      if(key === "page" || key === "type") return false; 
                      else if(key === "status" && !query.status) return false;
                      else if(key === "display_id" && !query.display_id) return false;
                      else return true;
                    }).length > 0
                    ?
                      [
                        {
                          text: Translate("orders", "resetfilter"),
                          to: `/refunds`,
                        }
                      ]
                    :
                      null
                  }
                />
              :
              <>
                <CustomTable 
                  columns={table_cols} 
                  data={
                    GettingRefunds === "2"
                    ? refundsData.results
                    : []
                  } 
                  loading={GettingRefunds === "1"}
                  border={false}
                  sticky={true}
                />
                <div role="pagination" className="lytWdp SmMarS1">
                  <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                    <span className="d-large">
                      {
                        Translate("orders", "showing") +
                        " " +
                        ((query.page ?? 1) * 20 - 19) +
                        " - " +
                        (
                          (query.page ?? 1) * 20 < (refundsData?.count ?? 0)
                          ? (query.page ?? 1) * 20
                          : refundsData?.count ?? 0
                        ) +
                        " " +
                        Translate("orders", "of") +
                        " " +
                        refundsData?.count ?? 0
                      }
                    </span>
                    <PaginationBar
                      className="StAutMr"
                      NbPage={Math.trunc((refundsData?.count ?? 0) / 20) + 1}
                      currentPage={query.page ?? 1}
                      ChangePage={onChangePage}
                      blockNext={refundsData?.next}
                      blockPrev={refundsData?.previous}
                    />
                  </div>
                </div>
              </>
            }
          </div>
        :
          <FeatureLoked/>
      }
    </ContainerPage>
  );
}

function mapState(state: any) {
  const { GettingRefunds, refundsData } = state.refunds;
  const { dataProfile, dtStoreinfo } = state.user;
  return {
    GettingRefunds,
    refundsData,
    dataProfile,
    dtStoreinfo
  }
}

const actionCreators = {
  GetRefunds: RefundActions.GetRefunds,
  SendAlert: alertActions.SendAlert,
}

const connectedRefundsPage = connect(mapState, actionCreators)(RefundsPage);

export { connectedRefundsPage as RefundsPage };