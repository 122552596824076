import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { product, Stock, UnlinkProduct } from "./product.reducer";
import { orders, unfOrders, confOrders } from "./orders.reducer";
import { pickups } from "./pickups.reducer";
import { exchanges } from "./exchange.reducer";
import { common } from "./common.reducer";
import { user, team, memberTeam } from "./user.reducer";
import { alertReducer } from "./updateMessage.reducer";
import { Viewpayment, facturepayment, paymentMethod } from "./payment.reducer";
import { dashboard } from "./dashboard.reducer";
import { alert } from "./alert.reducer";
import { notifications } from "./notifications.reducer";
import { listener } from "./Listener.reducer";
import { search } from "./search.reducer";
import { sidebar } from "./sideBar";
import { rewards } from "./rewards.reducer";
import { teams } from "./teams.reducer";
import { refunds } from "./refund.reducer";
const rootReducer = combineReducers({
  authentication,
  registration,
  product,
  orders,
  pickups,
  exchanges,
  common,
  Stock,
  user,
  team,
  unfOrders,
  confOrders,
  memberTeam,
  Viewpayment,
  facturepayment,
  UnlinkProduct,
  dashboard,
  alert,
  notifications,
  listener,
  search,
  paymentMethod,
  sidebar,
  alertReducer,
  rewards,
  teams,
  refunds
});

export default rootReducer;
