import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { initGAPg, Event } from "./_Common";
import { SideBar } from "./SideBar";
import { NavBar } from "./NavBar";
import {
  ProductsPage,
  ProductDetailPage,
  ReceiveProduct,
  TakenProduct,
  DetailsReceive,
  DetailsExit,
  ImportProductPage,
} from "./ProductsPage";
import { SettingsPage } from "./SettingsPage";
import { PaymentsPage } from "./PayementsPage";
import { Dashboard } from "./DashboardPage";
import { NotificationPage } from "./Notifications";
import {
  OrdersDetailPage as ConfirmationOrderDetailPage,
  ConfirmationPage,
} from "./ConfirmationPage";
import { userActions, alertActions, notificationsActions, commonActions, exchangeActions } from "../../actions";
import { UnfulfilledOrderPage } from "./UnfulfilledOrderPage";
import { SearchPage } from "./SearchPage";

import {
  AddOrderPage,
  OrdersDetailPage,
  OrdersPage,
  ExchangePage,
  RefundPage,
  ImportOrderPage,
  ImportOrdersProdPage,
} from "./OrdersPage";
import { AddPickupPage, PickupDetailPage, PickupsPage } from "./PickupPage";
import {
  AddStockPickupPage,
  StockPickupDetailPage,
  StockPickupsPage,
} from "./StockPickupPage";
import { AlertPage } from "./AlertPage";
import { PDFgenrator } from "./_Common";
import { Translate } from "../../utils/lang/translate";
import { ListenerFile } from "./ListenerEvent";
import { CloseSvg } from "./_Common/IconSvg";
import { BackupPage } from "./BackupPage/BackupPage";
import NewFeaturesModal from "./_Custom/NewFeaturesModal";
import { NEW_FEATURES, PROMOTION } from "../../utils/new-features";
import WelcomeModal from "./_Custom/WelcomeModal";
import NewWilayasModal from "./_Custom/NewWilayasModal";
import PromotionModal from "./_Custom/PromotionModal";
import { date_difference } from "../../utils/functions";
import CongratulationModal from "./_Custom/CongratulationModal";
import NewsStatusesWorkFlow from "./_Custom/workflows/NewsStatusesWorkFlow";
import { is_exclusive_test_store } from "../helpers/functions";
import SecurityUpdateModal from "./_Custom/SecurityUpdateModal";
import Cookies from "js-cookie";
import UnfulfilledOrderDetails from "./UnfulfilledOrderPage/UnfulfilledOrderDetails";
import { ExchangeDetailsPage } from "./ExchangeRefund/ExchangeDetailsPage";
import { userConstants } from "../../constants";
import ExchangeGuideModal from "./_Custom/ExchangeGuideModal";
import { RewardsPage } from "./Rewards/RewardsPage";
import { TeamsPage } from "./TeamsPage/TeamsPage";
import { TeamMembrePage } from "./TeamsPage/TeamMemberPage";
import AboutUsModal from "./_Custom/AboutUsModal";
import { PersonalInfo } from "./SettingsPage/PersonalInfoPage/PersonalInfo";
import { StockReleasePage } from "./ProductsPage/StockReleases/StockRelease";
import { ExchangeRefundPage } from "./ExchangeRefund/ExchangeRefundPage";
import MaystroLogo from "../../svgs/MaystroSvg";
import { AddOrder } from "./OrdersPage/AddOrder";
import { AddExchange } from "./OrdersPage/AddExchange";
import { AddRefund } from "./OrdersPage/AddRefund";
import { ApproveUnfOrder } from "./UnfulfilledOrderPage/ApproveUnfOrder";
import { RefundsPage } from "./ExchangeRefund/RefundsPage";

const lang = Cookies.get("lang");

class IndexStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewWilayasAnnoun: false,
      showNewWilayasPopup: false,
      showNewFeatAnnoun: true,
      showNewFeaturePopup: false,
      showPromotionAnnoun: true,
      showPromotionPopup: false,
      showCongratsPopup: false,
      showSecurityUpdatePopup: false,
    };
    this.handleCloseExchangeGuideModal = this.handleCloseExchangeGuideModal.bind(this);
  }
  
  componentDidMount() {

    var inputs = document.getElementsByTagName("input");
    for (var i = 0; i < inputs.length; i++)
      inputs[i].removeAttribute("autocomplete");

    initGAPg();
    this.props.GetStoreInfo();
    this.props.GetProfileUser();
    this.props.GetAnnouncement();
    

    let distance = date_difference(new Date(PROMOTION.enddate), new Date());

    if (this.state.showPromotionAnnoun) {
      if (distance.difference > 0) {
        let chron = setInterval(() => {
          let remaining = date_difference(
            new Date(PROMOTION.enddate),
            new Date()
          );
          if (remaining.difference <= 0) {
            clearInterval(chron);
          } else
            this.setState({
              remaining: `${remaining.days} :${remaining.hours}: ${remaining.minutes}: ${remaining.seconds}`,
            });
        }, 1000);
      } else {
        if (distance.days > -7) {
          this.setState({ remaining: null });
        } else {
          this.setState({ showPromotionAnnoun: false });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(
      this.props.gtStoreInf !== prevProps.gtStoreInf
      && this.props.gtStoreInf === "1"
    ){
      this.props.GetInfoMaystro(this.props.dtStoreinfo.country.id);
    }

    if(
      prevProps.UpdatingExchangePopups !== this.props.UpdatingExchangePopups
      && this.props.UpdatingExchangePopups === "3"
    ){
      this.props.SendAlert("50", "failed to update", "");
    }

    if (this.props.GetingUser === "2" && prevProps.GetingUser !== this.props.GetingUser) {
      this.props.SendAlert("50", Translate("alert", "failLdus"));
      this.props.Logout();
    }
    if (this.props.logout !== prevProps.logout && this.props.logout === "LOGOUT") {
      Cookies.remove("auth");
      Cookies.remove("c_store");
      this.props.history.push("/login");
    }
  }

  clickNewWilayasHandler() {
    this.setState({ showNewWilayasPopup: true });
    Event("ANNOUCEMENT", "OPEN_NEW_WILAYAS_POPUP", "CLICK_EVENT");
  }
  clickNeFeaturesHandler() {
    this.setState({ showNewFeaturePopup: true });
    Event("ANNOUCEMENT", "OPEN_NEW_FEATURES_POPUP", "CLICK_EVENT");
  }
  clickNewPromotionHandler() {
    this.setState({ showPromotionPopup: true });
    Event("ANNOUCEMENT", "OPEN_NEW_PROMOTION_POPUP", "CLICK_EVENT");
  }
  closeNewWilayasHandler() {
    this.setState({ showNewWilayasPopup: false });
    Event("ANNOUCEMENT", "CLOSE_NEW_WILAYAS_POPUP", "CLICK_EVENT");
  }
  closeNeFeaturesHandler() {
    this.setState({ showNewFeaturePopup: false });
    Event("ANNOUCEMENT", "CLOSE_NEW_FEATURES_POPUP", "CLICK_EVENT");
  }
  closeMessageAlert() {
    this.setState({ showNewFeatAnnoun: false });
  }
  closeNewPromotionHandler() {
    this.setState({ showPromotionPopup: false });
    Event("ANNOUCEMENT", "CLOSE_NEW_PROMOTION_POPUP", "CLICK_EVENT");
  }
  closeCongratsHandler() {
    this.setState({ showCongratsPopup: false });
    Event("ANNOUCEMENT", "CLOSE_CONGRATS_POPUP", "CLICK_EVENT");
  }
  closeSecurityUpdateHandler() {
    this.setState({ showSecurityUpdatePopup: false });
    Event(
      "SECURITY_UPDATE",
      "CLOSE_SECCHECK_POPUP",
      `${this.props.dtStoreinfo.name}`
    );
  }
  closeShowWelcomeMessageHandler(){
    localStorage.removeItem("new_user");
    this.setState({
      showWelcomeMessage: false
    });
    this.props.HideWelcomeMessage();
    Event(
      "ANNOUNCEMENT",
      "CLOSE_WELCOME_MESSAGE",
      "CLICK_EVENT"
    );
  }

  handleCloseExchangeGuideModal(neverShowAgain) {
    this.props.ShowExchangeGuideModal(false);
    if(neverShowAgain){
      if(this.props.dtStoreinfo?.show_exchange_popups === userConstants.SHOW_BOTH_EXCHANGE_POPUPS){
        this.props.UpdateShowExchangePopups(2);
      }
      if(this.props.dtStoreinfo?.show_exchange_popups === userConstants.SHOW_ONLY_EXCHANGE_GUIDE_POPUP){
        this.props.UpdateShowExchangePopups(3);
      }
    }
  };

  render() {
    const { dtStoreinfo, dataProfile, GetingUser, gtStoreInf, showAboutUsModal, UpdateShowAboutUsModal } = this.props;
    let features = [];
    if (dtStoreinfo && is_exclusive_test_store(dtStoreinfo))
      features.push(
        !dtStoreinfo.stock_managed ? NEW_FEATURES[3] : NEW_FEATURES[2]
      );

    return (
      <div className="BdgGc">
        {
          showAboutUsModal
          && (
            <AboutUsModal 
              info={this.props.dataMaystro} 
              onClose={() => UpdateShowAboutUsModal(false)}
            />
          )
        }
        {
          this.props.ShowWelcomeMessage
          && (
            <WelcomeModal
              info={this.props.dataMaystro} 
              onClose={() => this.closeShowWelcomeMessageHandler()} 
            />  
          )
        }
        {
          (
            dtStoreinfo?.show_exchange_popups === userConstants.SHOW_BOTH_EXCHANGE_POPUPS
            || dtStoreinfo?.show_exchange_popups === userConstants.SHOW_ONLY_EXCHANGE_GUIDE_POPUP
          ) 
          && this.props.showExchangeGuideModal
          &&
          <ExchangeGuideModal 
            onClose={this.handleCloseExchangeGuideModal}
          />
        }
        {this.state.showNewWilayasAnnoun && (
          <div
            id="tab_wilayas_inform"
            className="InFlx flxDrc Tstatus_31 JstfCnt TxtCn CrsPoi"
            style={{ padding: "10px 5px" }}
            onClick={() => this.clickNewWilayasHandler()}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: Translate("statusorder", "textadd2"),
              }}
            />
          </div>
        )}
        {this.state.showNewWilayasPopup && (
          <NewWilayasModal onClose={() => this.closeNewWilayasHandler()} />
        )}

        {this.props.Gettingmessage == 2 &&
          (this.props.dataAnnouncement?.country === dtStoreinfo?.country?.id ? (
            <div>
              {features.length > 0 && this.state.showNewFeatAnnoun && (
                <div
                  className="InFlx flxDrc Tstatus_31"
                  style={{ padding: "10px 0px" }}
                >
                  <div
                    className="InFlx flxDrc JstfCnt"
                    style={{ width: "98%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <b
                        className="status_41"
                        style={{
                          padding: "5px",
                          borderRadius: "10%",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      >
                        {Translate("titles", "new")}
                      </b>

                      {lang == "en" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.en_content,
                          }}
                        ></span>
                      ) : lang == "fr" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.fr_content,
                          }}
                        ></span>
                      ) : lang == "ar" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.ar_content,
                          }}
                        ></span>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.en_content,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                  <div
                    className="InFlx AlgnItm JstfCnt CrsPoi"
                    style={{ width: "2%", zoom: 0.7 }}
                    onClick={() => this.closeMessageAlert()}
                  >
                    {CloseSvg}
                  </div>
                </div>
              )}{" "}
            </div>
          ) : (
            ""
          ))}

        {this.state.showNewFeaturePopup && (
          <NewFeaturesModal
            features={features}
            children={
              <NewsStatusesWorkFlow starter={!dtStoreinfo.stock_managed} />
            }
            onClose={() => this.closeNeFeaturesHandler()}
          />
        )}
        {/* Promotion */}
        {this.state.showPromotionAnnoun && (
          <div
            className="InFlx flxDrc Tstatus_50"
            style={{ padding: "10px 10px" }}
          >
            <div className="FlWd InFlx flxDrc JstfCnt">
              {this.state.remaining !== null && (
                <div>
                  <b
                    className="status_11"
                    style={{ padding: "5px", borderRadius: "10%" }}
                  >
                    {Translate("titles", "flash")}
                  </b>
                  <span>
                    {" "}
                    {Translate("announcements", "promotion-inttext")}
                  </span>
                  <b className="sTmrGlf"> {this.state.remaining} </b>
                  <b
                    className="CrsPoi stUndrln"
                    onClick={() => this.clickNewPromotionHandler()}
                  >
                    {" "}
                    {Translate("announcements", "seedetails")}{" "}
                  </b>
                </div>
              )}
              {this.state.remaining === null && (
                <div>
                  <b
                    className="status_51"
                    style={{ padding: "5px", borderRadius: "10%" }}
                  >
                    {Translate("announcements", "expired")}
                  </b>
                  <span> {Translate("announcements", "promotionended")}</span>
                  <b
                    className="CrsPoi stUndrln"
                    onClick={() => this.clickNewPromotionHandler()}
                  >
                    {" "}
                    {Translate("announcements", "seedetails")}{" "}
                  </b>
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.showPromotionPopup && (
          <PromotionModal onClose={() => this.closeNewPromotionHandler()} />
        )}
        {/* Winners Congratulations */}
        {this.state.showCongratsPopup && (
          <CongratulationModal
            onClose={() => this.closeCongratsHandler()}
            winnerName={dtStoreinfo.name}
            congratsContent={
              <p className="StOpcVal">
                {Translate("announcements", "congratstext")}
              </p>
            }
          />
        )}
        {/* Security Updates popup */}
        {GetingUser === "1" &&
          dtStoreinfo &&
          dataProfile &&
          dataProfile.is_store &&
          this.state.showSecurityUpdatePopup && (
            <SecurityUpdateModal
              onClose={() => this.closeSecurityUpdateHandler()}
              email={dataProfile.email}
              phone={dataProfile.phone}
              user={dataProfile}
            />
          )}
        <div className="InFlx align-items-start min-h-screen RlPs">
          {
            GetingUser === "0" || gtStoreInf === "0" ? 
              (
                <div className="FlWd FlHg StAbs Zindx InFlx AlgnItm JstfCnt" style={{ backgroundColor: "#1B2431" }}>
                  <div className="animate-splash" style={{ transform: "translate(-20px,-20px)" }}>
                    <MaystroLogo height={350} width={350} fill="#008aff"/>
                  </div>
                </div>
              ) 
            :
            (
              (
                GetingUser === "1" &&
                gtStoreInf === "1" && 
                dataProfile && 
                dtStoreinfo
              ) 
              && 
              (
                <>
                  <SideBar pathname={window.location.pathname} />
                  <div className={`FlWd InFlx Stclmnf h-screen OvfAtoY main-container ${this.props.sideState && "main-container-expand"}`}>
                    
                    <NavBar {...this.props} />

                    <Switch>
                      <Route path="/dashboard" component={Dashboard} exact />
                      <Route path="/order/:orderId" component={AddOrder} exact/>
                      <Route
                        path="/order/detail/:idorder"
                        component={OrdersDetailPage}
                        exact
                      />
                      <Route 
                        path="/exchange/:orderId" 
                        component={AddExchange}
                        exact
                      />
                      <Route
                        path="/refund/:orderId"
                        component={AddRefund}
                        exact
                      />
                      <Route
                        path="/order/import"
                        component={ImportOrderPage}
                        exact
                      />
                      <Route
                        path="/order/Prdimport"
                        component={ImportOrdersProdPage}
                        exact
                      />
                      <Route
                        path={"/unfulfilled/:orderId/approve"}
                        component={ApproveUnfOrder}
                        exact
                      />
                      <Route path="/orders" component={OrdersPage} exact />
                      {
                        !dtStoreinfo.stock_managed
                        &&
                        <Route path="/exchange-refund-list/:type" component={ExchangeRefundPage} exact/>
                      }
                      {
                        <Route path={"/refunds"} component={RefundsPage} exact/>
                      }
                      {
                        !dtStoreinfo.stock_managed
                        &&
                        <Route path="/exchange-refund/:exchange_refund_id" component={ExchangeDetailsPage} exact/>  
                      }
                      {dataProfile.intgraed &&
                        (dataProfile.is_store || dataProfile.view_order) && (
                          <Route
                            path="/unfulfilled/order/:idprod"
                            component={UnfulfilledOrderDetails}
                            exact
                          />
                        )}
                      {dataProfile.intgraed &&
                        (dataProfile.is_store || dataProfile.view_order) && (
                          <Route
                            path="/unfulfilled/order"
                            component={UnfulfilledOrderPage}
                            exact
                          />
                        )}
                      {dtStoreinfo.stock_managed && (
                        <Route
                          path="/products/detail/:idprod"
                          component={ProductDetailPage}
                          exact
                        />
                      )}
                      <Route path="/stock/products" component={ProductsPage} />
                      <Route path="/payments" component={PaymentsPage} />
                      {(dataProfile.is_store || dataProfile.add_pickup) &&
                        !dtStoreinfo.stock_managed && (
                          <Route
                            path="/pickups/add/:idpickup"
                            component={AddPickupPage}
                            exact
                          />
                        )}
                      {(dataProfile.is_store || dataProfile.add_pickup) &&
                        dtStoreinfo.stock_managed && (
                          <Route
                            path="/pickups/add/:idpickup"
                            component={AddStockPickupPage}
                            exact
                          />
                        )}
                      <Route
                        path="/stock/receiveProduct"
                        component={ReceiveProduct}
                        exact
                      />
                      <Route
                        path="/stock/takenProduct"
                        component={TakenProduct}
                        exact
                      />
                      <Route
                        path="/stock/detail/:id"
                        component={DetailsReceive}
                        exact
                      />
                      <Route
                        path="/stock/detailTaken/:id"
                        component={DetailsExit}
                        exact
                      />
                      {
                        dtStoreinfo.stock_managed
                        &&
                        <Route 
                          path="/stock/stock-release"
                          component={StockReleasePage} 
                          exact
                        />
                      }
                      <Route
                        path="/products/import"
                        component={ImportProductPage}
                        exact
                      />
                      {(dataProfile.is_store || dataProfile.view_pickup) &&
                        !dtStoreinfo.stock_managed && (
                          <Route
                            path="/pickups/detail/:idpickup"
                            component={PickupDetailPage}
                            exact
                          />
                        )}
                      {(dataProfile.is_store || dataProfile.view_pickup) &&
                        dtStoreinfo.stock_managed && (
                          <Route
                            path="/pickups/detail/:idpickup"
                            component={StockPickupDetailPage}
                            exact
                          />
                        )}
                      {(dataProfile.is_store || dataProfile.view_pickup) &&
                        !dtStoreinfo.stock_managed && (
                          <Route path="/pickups" component={PickupsPage} exact />
                        )}
                      {(dataProfile.is_store || dataProfile.view_pickup) &&
                        dtStoreinfo.stock_managed && (
                          <Route
                            path="/pickups"
                            component={StockPickupsPage}
                            exact
                          />
                        )}
                      {dtStoreinfo.is_gold && (
                        <Route
                          path="/confirmation/detail/:idorder"
                          component={ConfirmationOrderDetailPage}
                          exact
                        />
                      )}
                      {dtStoreinfo.is_gold && (
                        <Route
                          path="/confirmation"
                          component={ConfirmationPage}
                          exact
                        />
                      )}
                      <Route path="/rewards" component={RewardsPage}/>
                      <Route path="/settings/personal-info" component={PersonalInfo} exact/>
                      <Route path="/settings/teams/:memberId" component={TeamMembrePage} exact/>
                      <Route path="/settings/teams" component={TeamsPage} exact/>
                      <Route path="/settings" component={SettingsPage} />
                      <Route path="/backup" component={BackupPage} exact />
                      <Route
                        path="/notifications"
                        component={NotificationPage}
                        exact
                      />
                      <Route path="/search" component={SearchPage} exact />
                      <Route
                        path="/print/facture/:operation"
                        component={PDFgenrator}
                        exact
                      />
                      <Redirect from="*" to="/dashboard" />
                    </Switch>
                  </div>
                  <ListenerFile />
                </>
              )
            )
          }
          <AlertPage />
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { GetingUser, gtStoreInf, dtStoreinfo, dataProfile, ShowWelcomeMessage, showAboutUsModal } = state.user;
  const { Gettingmessage, dataAnnouncement } = state.alertReducer;
  const { GetingInfomaystro, dataMaystro } = state.common;
  const { UpdatingExchangePopups, showExchangeGuideModal } = state.exchanges;
  const { sideState } = state.sidebar;
  const { logout } = state.authentication;

  return {
    GetingUser,
    gtStoreInf,
    dtStoreinfo,
    dataProfile,
    Gettingmessage,
    dataAnnouncement,
    ShowWelcomeMessage,
    GetingInfomaystro,
    dataMaystro,
    UpdatingExchangePopups,
    showExchangeGuideModal,
    sideState,
    showAboutUsModal,
    logout
  };
}

const actionCreators = {
  GetProfileUser: userActions.GetProfileUser,
  SendAlert: alertActions.SendAlert,
  GetAnnouncement: notificationsActions.GetAnnouncement,
  HideWelcomeMessage: userActions.HideWelcomeMessage,
  GetInfoMaystro: commonActions.GetInfoMaystro,
  UpdateShowExchangePopups: exchangeActions.UpdateShowExchangePopups,
  ShowExchangeGuideModal: exchangeActions.ShowExchangeGuideModal,
  UpdateShowAboutUsModal: userActions.UpdateShowAboutUsModal,
  GetStoreInfo: userActions.GetStoreInfo,
  Logout: userActions.Logout,
};

const connectedIndexStore = connect(mapState, actionCreators)(IndexStore);
export { connectedIndexStore as IndexStore };
